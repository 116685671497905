export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES'
export const GET_CURRENT_USER = 'GET_CURRENT_USER'
export const GET_CURRENT_USER_POSTS = 'GET_CURRENT_USER_POSTS'
export const GET_ALL_USERS = 'GET_ALL_USERS'
export const GET_ALL_POSTS = 'GET_ALL_POSTS'
export const DELETE_CURRENT_USER = 'DELETE_CURRENT_USER'
export const GET_SEARCHED_POSTS = 'GET_SEARCHED_POSTS'
export const SEARCH_QUERY = 'SEARCH_QUERY'
export const TOGGLE_THEME = 'TOGGLE_THEME'
export const GET_CATEGORY_POSTS = 'GET_CATEGORY_POSTS'
export const UPDATE_STATUS = 'UPDATE_STATUS'
export const GET_AUTHOR_POSTS_STATUS = 'GET_AUTHOR_POSTS_STATUS'
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE'
export const SET_PAGE_DESCRIPTION = 'SET_PAGE_DESCRIPTION'
